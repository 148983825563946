<template>
  <div class="row">
    <div class="wrapper col-md-3">
      <div v-if="USER_INFO.status === 'admin'" class="list">
        <ul class="list-group">
          <li
            class="list-group-item list-group-item-action p-0"
            v-if="USER_INFO.gruppa === 'super'"
          >
            <router-link to="/kabinet/close-site" exact class="nav-link"
              >доступ на сайт</router-link
            >
          </li>
          <li
            class="list-group-item list-group-item-action p-0"
            v-if="USER_INFO.gruppa === 'super'"
          >
            <router-link
              to="/kabinet/admin/file-manager/"
              exact
              class="nav-link"
              >менеджер файлов</router-link
            >
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/users" exact class="nav-link"
              >пользователи</router-link
            >
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/blank" exact class="nav-link"
              >загрузка бланка</router-link
            >
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/upload-instr" exact class="nav-link"
              >загрузка номенклатуры по инструменту</router-link
            >
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/stats" exact class="nav-link"
              >статистика по инструменту</router-link
            >
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/stats-sc" exact class="nav-link"
              >статистика по СЦ</router-link
            >
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/upload-realization" exact class="nav-link"
              >загруженные реализации
            </router-link>
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/not-cod-in-sheme" exact class="nav-link"
              >схемы без кодов
            </router-link>
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link
              to="/kabinet/search-zap-in-repairs"
              exact
              class="nav-link"
              >поиск запчастей в ремонтах
            </router-link>
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/otpravki" exact class="nav-link"
              >отправки
            </router-link>
          </li>
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/menedger-stats" exact class="nav-link"
              >статистика по менеджерам
            </router-link>
          </li>
        </ul>
      </div>
      <div v-else>
        <ul class="list-group">
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/users/user/1" exact class="nav-link"
              >реквизиты</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="wrapper col-md-3">
      <div v-if="USER_INFO.status === 'admin'" class="list">
        <ul class="list-group">
          <li class="list-group-item list-group-item-action p-0">
            <router-link to="/kabinet/list-sc" exact class="nav-link"
              >список СЦ с kalibrcompany.ru</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Kabinet",
  computed: {
    ...mapGetters(["USER_INFO"])
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 5px;
}
ul {
  list-style: none;
}
</style>
